import URLBuilder from '@/lib/url-builder';

export default function contentNewsDetailUrl(
  slug: string,
  urlQuerySearchParams?: URLSearchParams | null | undefined,
  urlHash?: string | null | undefined,
): string {
  const url = new URLBuilder(`/zpravy/${slug}`, urlQuerySearchParams, urlHash);
  return url.build();
}
