import { ProductCard } from '@sprinx-app/ui-commerce';
import { useIntl } from 'react-intl';
import contentProductDetailUrl from '@/content/content-product-detail-url';
import priceFormatted from '@/lib/price-formatted';
import productImageLoader from '@/lib/product-image-loader';
import type { ElementType } from 'react';

export type ProductCardStyledProduct = {
  colors: { color: string; name: string }[];
  currency: string;
  description: string | null;
  id: number;
  name: string;
  priceList: number;
  priceListWithTax: number;
  priceOrigin: number | null;
  priceOriginWithTax: number | null;
  primaryImage: {
    alt: string | null;
    height: number | null;
    src: string;
    width: number | null;
  } | null;
  sku: string;
};

type ProductCardStyledProps = ProductCardStyledProduct & {
  as?: ElementType;
};

export default function ProductCardStyled({ as = 'div', ...product }: ProductCardStyledProps) {
  const intl = useIntl();

  return (
    <ProductCard
      as={as}
      className='border-surface-divid group relative flex flex-col overflow-hidden rounded-lg border bg-white'
      href={contentProductDetailUrl(product.sku, product.name)}
    >
      <ProductCard.Image
        alt={product.primaryImage?.alt ?? product.name}
        className='aspect-[3/4] h-60 p-6 shadow group-hover:opacity-75 sm:h-96'
        height={product.primaryImage?.height ?? 384}
        imageClassName='h-full w-full object-contain object-center'
        loader={productImageLoader}
        src={product.primaryImage?.src}
        unoptimized
        width={product.primaryImage?.width ?? 288}
      />
      <div className='flex flex-1 flex-col space-y-2 p-4'>
        <ProductCard.Heading className='text-content-focus mt-2 font-semibold'>{product.name}</ProductCard.Heading>
        <div className='mt-4 flex flex-1 flex-col justify-end'>
          <ProductCard.Colors
            className='mt-1 flex items-center justify-center space-x-3 py-3'
            heading='Available colors'
            itemClassName='h-4 w-4 rounded-full border border-black border-opacity-10'
            items={product.colors}
          />
          <ProductCard.Price className='text-content-focus mt-1'>
            {
              priceFormatted(intl, product.currency, product.priceList)
              // 'Cena na poptání'
            }
          </ProductCard.Price>
        </div>
      </div>
    </ProductCard>
  );
}
