import Image from 'next/image';
import Link from 'next/link';
import classNames from './class-names';
import type { ComponentProps, ElementType, ReactElement, ReactNode } from 'react';

// *****************************************
// *
// * ArticleListRoot
// *
// *****************************************

const ArticleListRootDefaultElement: ElementType = 'div';

interface ArticleListRoot {
  <E extends ElementType = typeof ArticleListRootDefaultElement>(props: ArticleListRootProps<E>): ReactElement | null;
  displayName: string;
}

type ArticleListRootProps<E extends ElementType> = ArticleListRootOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListRootOwnProps>;

type ArticleListRootOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ArticleListRoot: ArticleListRoot = ({
  as: Component = ArticleListRootDefaultElement,
  children,
  className,
  ...rest
}) => {
  return (
    <Component
      className={
        className == null ? 'grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4' : className
      }
      {...rest}
    >
      {children}
    </Component>
  );
};

ArticleListRoot.displayName = 'ArticleList';

// *****************************************
// *
// * ArticleListItem
// *
// *****************************************

const ArticleListItemDefaultElement: ElementType = 'article';

interface ArticleListItem {
  <E extends ElementType = typeof ArticleListItemDefaultElement>(props: ArticleListItemProps<E>): ReactElement | null;
  displayName: string;
}

type ArticleListItemProps<E extends ElementType> = ArticleListItemOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListItemOwnProps>;

type ArticleListItemOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: ReactNode;
  className?: string;
  href: string;
  prefetch?: boolean;
};

const ArticleListItemRoot: ArticleListItem = ({
  as: Component = ArticleListItemDefaultElement,
  children,
  className,
  href,
  prefetch = false,
  ...rest
}) => {
  return (
    <Component
      className={classNames('relative', className == null ? 'h-auto rounded shadow-lg hover:shadow-xl' : className)}
      {...rest}
    >
      {children}
      <Link className='absolute inset-0' href={href} prefetch={prefetch}></Link>
    </Component>
  );
};

ArticleListItemRoot.displayName = 'ArticleList_Item';

// *****************************************
// *
// * ArticleListItemImage
// *
// *****************************************

const ArticleListItemImageDefaultElement: ElementType = 'div';

interface ArticleListItemImage {
  <E extends ElementType = typeof ArticleListItemImageDefaultElement>(
    props: ArticleListItemImageProps<E>,
  ): ReactElement | null;
  displayName: string;
}

type ArticleListItemImageProps<E extends ElementType> = ArticleListItemImageOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListItemImageOwnProps>;

type ArticleListItemImageOwnProps<E extends ElementType = ElementType> = {
  alt: string;
  as?: E;
  className?: string;
  height?: number;
  imageFit?: 'cover' | 'contain';
  src: string | null | undefined;
  unoptimized?: boolean;
  width?: number;
};

const ArticleListItemImage: ArticleListItemImage = ({
  alt,
  as: Component = ArticleListItemImageDefaultElement,
  className,
  height = 300,
  imageFit = 'cover',
  src,
  unoptimized = false,
  width = 400,
}) => {
  return (
    <Component className={className == null ? 'aspect-[4/3] w-full last:rounded-b' : className}>
      {src && (
        <Image
          alt={alt}
          // className={classNames(
          //   'h-full w-full rounded-t',
          //   imageFit === 'cover' && 'object-cover',
          //   imageFit === 'contain' && 'object-contain',
          // )}
          data-imagefit={imageFit}
          height={height}
          src={src}
          unoptimized={unoptimized}
          width={width}
        />
      )}
    </Component>
  );
};

ArticleListItemImage.displayName = 'ArticleList_Item_Image';

// *****************************************
// *
// * ArticleListItemHeading
// *
// *****************************************

const ArticleListItemHeadingDefaultElement: ElementType = 'h1';

interface ArticleListItemHeading {
  <E extends ElementType = typeof ArticleListItemHeadingDefaultElement>(
    props: ArticleListItemHeadingProps<E>,
  ): ReactElement | null;
  displayName: string;
}

type ArticleListItemHeadingProps<E extends ElementType> = ArticleListItemHeadingOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListItemHeadingOwnProps>;

type ArticleListItemHeadingOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: ReactNode;
  className?: string;
};

const ArticleListItemHeading: ArticleListItemHeading = ({
  as: Component = ArticleListItemHeadingDefaultElement,
  children,
  className,
  ...rest
}) => {
  return (
    <Component className={className == null ? 'my-4 px-6 text-xl font-bold last:pb-6' : className} {...rest}>
      {children}
    </Component>
  );
};

ArticleListItemHeading.displayName = 'ArticleList_Item_Heading';

// *****************************************
// *
// * ArticleListItemText
// *
// *****************************************

const ArticleListItemTextDefaultElement: ElementType = 'p';

interface ArticleListItemText {
  <E extends ElementType = typeof ArticleListItemTextDefaultElement>(
    props: ArticleListItemTextProps<E>,
  ): ReactElement | null;
  displayName: string;
}

type ArticleListItemTextProps<E extends ElementType> = ArticleListItemTextOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListItemTextOwnProps>;

type ArticleListItemTextOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: ReactNode;
  className?: string;
};

const ArticleListItemText: ArticleListItemText = ({
  as: Component = ArticleListItemTextDefaultElement,
  children,
  className,
  ...rest
}) => {
  if (children == null) return null;
  return (
    <Component className={className == null ? 'mt-2 px-6 text-base last:pb-6' : className} {...rest}>
      {children}
    </Component>
  );
};

ArticleListItemText.displayName = 'ArticleList_Item_Text';

// *****************************************
// *
// * ArticleListItemActions
// *
// *****************************************

const ArticleListItemActionsDefaultElement: ElementType = 'div';

interface ArticleListItemActions {
  <E extends ElementType = typeof ArticleListItemActionsDefaultElement>(
    props: ArticleListItemActionsProps<E>,
  ): ReactElement | null;
  displayName: string;
}

type ArticleListItemActionsProps<E extends ElementType> = ArticleListItemActionsOwnProps<E> &
  Omit<ComponentProps<E>, keyof ArticleListItemActionsOwnProps>;

type ArticleListItemActionsOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children: ReactNode;
  className?: string;
};

const ArticleListItemActions: ArticleListItemActions = ({
  as: Component = ArticleListItemActionsDefaultElement,
  children,
  className,
  ...rest
}) => {
  return (
    <Component className={className == null ? 'mt-4 flex flex-1 items-end px-6 last:pb-6' : className} {...rest}>
      {children}
    </Component>
  );
};

ArticleListItemActions.displayName = 'ArticleList_Item_Actions';

// *****************************************
// *
// * ArticleListItem
// *
// *****************************************

const ArticleListItem = Object.assign(ArticleListItemRoot, {
  Actions: ArticleListItemActions,
  Heading: ArticleListItemHeading,
  Image: ArticleListItemImage,
  Text: ArticleListItemText,
});

// *****************************************
// *
// * ArticleList
// *
// *****************************************

const ArticleList = Object.assign(ArticleListRoot, {
  Item: ArticleListItem,
});

export default ArticleList;
