import { NextSeo } from 'next-seo';
import LayoutBase from '@/components/layout-base';
import contentHomeData from '@/content/content-home-data';
import type { ContentHomePageProps } from '@/content/content-home-page';
import ContentHomePage from '@/content/content-home-page';
import contentLayoutBaseData from '@/content/content-layout-base-data';
import i18nMessagesLoad from '@/lib/i18n-messages-load';
import { localizationContextFromGetStaticPropsContext } from '@/lib/localization-contenxt';
import revalidateAfter from '@/lib/revalidate-after';
import { SEO_DEFAULT_TITLE } from '@/lib/seo-config';
import type { NextGetStaticProps, NextPageWithLayout } from './_app';

type HomePageProps = {} & ContentHomePageProps;

const HomePage: NextPageWithLayout<HomePageProps> = (props) => {
  return (
    <>
      <NextSeo />

      <main>
        <h1 className='sr-only'>{SEO_DEFAULT_TITLE}</h1>
        <ContentHomePage
          hero={props.hero}
          newsArticles={props.newsArticles}
          promotionProducts={props.promotionProducts}
          topCategories={props.topCategories}
          whyUs={props.whyUs}
        />
      </main>
    </>
  );
};

HomePage.displayName = 'HomePage';

export default HomePage;

HomePage.getLayout = (page) => {
  return <LayoutBase>{page}</LayoutBase>;
};

export const getStaticProps: NextGetStaticProps<HomePageProps> = async (getStaticPropsContext) => {
  const localizationContext = localizationContextFromGetStaticPropsContext(getStaticPropsContext);
  const intlMessages = await i18nMessagesLoad(localizationContext);
  const layoutData = await contentLayoutBaseData(localizationContext);
  const homePageContentProps = await contentHomeData(localizationContext);

  return {
    props: {
      ...homePageContentProps,
      intlMessages,
      layoutData,
    },
    revalidate: revalidateAfter('1h'),
  };
};
